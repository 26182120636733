<template>
  <div style="display: none">
    <div id="webcam" class="hide">
      <video autoplay="" id="video-player"></video>
    </div>
    <div id="displayMedia" class="hide">
      <video autoplay="" width="400" height="300" id="display-media-player"></video>
      <img src="" alt="" width="640" height="400" id="display-media-screen">
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import captureVideoFrame from '../../public/captureVideoFrame';
let userMediaStreamObject = {};
let displayMediaStreamObject = {};
export default {
  name: 'Proctoring',
  mounted() {
    let quiz = JSON.parse(localStorage.getItem('quiz'));
    if (quiz && quiz.proctoring){
      if(quiz.proctoring == 1) {
        navigator.mediaDevices.getUserMedia({video: true})
            .then(mediaStream => {
              mediaStream.getVideoTracks()[0].onended = function () {
                window.location.href = '/completed';
              };
              this.userMediaStreamObject = mediaStream
              const videoPlayer = document.querySelector('video#video-player');
              const webcam = document.querySelector('#webcam');
              videoPlayer.srcObject = mediaStream;
              webcam.classList.remove("hidden");
              webcam.classList.add("active");

              const saveUserMedia = function (){
                const cameraTrack = mediaStream.getVideoTracks()[0];
                const cameraImageCapture = new ImageCapture(cameraTrack);
                cameraImageCapture.takePhoto()
                    .then(blob => {
                      save(blob, 'user');
                    })
                    .catch(error => {
                      // console.log(error)
                    });
              }
              const catchInterruption = function () {
                const cameraTrack = mediaStream.getVideoTracks()[0];
                const cameraImageCapture = new ImageCapture(cameraTrack);
                cameraImageCapture.takePhoto()
                    .then(blob => {
                      save(blob, 'user', true);
                    })
                    .catch(error => {
                      // console.log(error);
                    });
              }
              window.onblur = function (){
                if (document.hidden) {
                  catchInterruption();
                }
              }
              document.addEventListener("visibilitychange", function() {
                if (document.hidden) {
                  catchInterruption();
                }
              });

              setTimeout(saveUserMedia, 5000);
              setInterval(saveUserMedia, 30000);
            })
            .catch(error => {
              if (this.userMediaStreamObject) {
                this.userMediaStreamObject.stop();
              }
              Vue.toastr({
                message: 'Внимание!',
                description: 'Вам необходимо разрешить доступ к веб-камере',
                type: 'error',
              });
              // this.$router.push({ name: 'about' });
              window.location.href = '/identification';
              // document.getElementById('permissionDenied').classList.add('active');
              // document.getElementById('webcam').classList.remove('active');
              // document.getElementById('webcam').classList.add('hidden');
              // console.log(error);
            });

        navigator.mediaDevices.getDisplayMedia({video: true})
            .then(mediaStream => {
              mediaStream.getVideoTracks()[0].onended = function () {
                window.location.href = '/completed';
              };
              this.displayMediaStreamObject = mediaStream;
              const displaySurface = mediaStream.getTracks()[0].getSettings().deviceId;
              if (displaySurface.substring(0, 12) === 'web-contents') {
                if (this.displayMediaStreamObject) {
                  this.displayMediaStreamObject.stop();
                }
                Vue.toastr({
                  message: 'Внимание!',
                  description: 'Вам необходимо разрешить доступ запись экрана',
                  type: 'error'
                });
                // this.$router.push({ name: 'about' });
                // window.location.href = '/about'
                window.location.href = '/identification';
                // document.getElementById('permissionDenied').classList.add('active');
                // document.getElementById('webcam').classList.remove('active');
                // document.getElementById('webcam').classList.add('hidden');
              }

              document.getElementById('display-media-player').srcObject = mediaStream;
              const saveDeviceMedia = function (){
                const frame = captureVideoFrame('display-media-player', 'png');
                save(frame.blob, 'device');
              }

              const catchInterruption = function (){
                const frame = captureVideoFrame('display-media-player', 'png');
                save(frame.blob, 'device', true);
              }

              window.onblur = function (){
                if (document.hidden) {
                  Vue.toastr({
                    message: 'Внимание!',
                    description: 'Не сворачивайте браузер пожалуйста!',
                    type: 'warning',
                    duration: 10000
                  });
                  setTimeout(catchInterruption, 500);
                }
              }
              document.addEventListener("visibilitychange", function() {
                if (document.hidden) {
                  Vue.toastr({
                    message: 'Внимание!',
                    description: 'Не переключайте вкладку пожалуйста!',
                    type: 'warning',
                    duration: 10000
                  });
                  setTimeout(catchInterruption, 500);
                }
              });

              setTimeout(saveDeviceMedia, 5000);
              setInterval(saveDeviceMedia, 30000);
            })
            .catch(error => {
              if (this.displayMediaStreamObject) {
                this.displayMediaStreamObject.stop();
              }
              Vue.toastr({
                message: 'Внимание!',
                description: 'Вам необходимо разрешить запись экрана',
                type: 'error',
              });
              // this.$router.push({ name: 'about' });
              // window.location.href = '/about'
              window.location.href = '/identification';
              // document.getElementById('permissionDenied').classList.add('active');
              // document.getElementById('webcam').classList.remove('active');
              // document.getElementById('webcam').classList.add('hidden');
              // console.log(error);
            });

      }
    }

    function save(blob, type, interruption = false) {
      const file = new File([blob], 'msr-' + (new Date).toISOString().replace(/:|\./g, '-') + '.gif', {
        type: 'image/gif'
      });

      let formData = new FormData();
      let filePath = 'interval/';
      if (interruption) filePath = 'interruptions/';
      filePath += type + '/' + file.name;
      let token = '';
      if (localStorage.getItem('quiz-token')) {
        token = localStorage.getItem('quiz-token');
      }
      formData.append('video-filename', filePath);
      formData.append('video-blob', file);
      formData.append('token', token);
      // formData.append('_token', document.getElementsByName('_token')[0].value);

      makeXMLHttpRequest(window.API_ROOT +  '/api/save-image', formData, function () {
        const downloadURL = filePath;
        // console.log('File uploaded to this path:', downloadURL);
      });
    }

    function makeXMLHttpRequest(url, data, callback) {
      let request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          callback();
        }
      };
      request.open('POST', url);
      request.send(data);
    }
  },
  beforeDestroy() {
    let quiz = JSON.parse(localStorage.getItem('quiz'));
    if (quiz && quiz.proctoring) {
      if (quiz.proctoring == 1) {
        if (this.displayMediaStreamObject) {
          this.displayMediaStreamObject.getTracks().forEach(element => {
            element.stop()
          });
        }
        if (this.userMediaStreamObject) {
          this.userMediaStreamObject.getTracks().forEach(element => {
            element.stop()
          });
        }
      }
    }
  }
}
</script>
