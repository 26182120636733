const SAVE_PASSWORD = 'SAVE_PASSWORD'
const FORGET_PASSWORD = 'FORGET_PASSWORD'

const state = {
  savedPassword: localStorage.getItem('pwd') || ''
}
const getters = {}

const mutations = {
  [SAVE_PASSWORD] (state, data) {
    state.savedPassword = data;
    localStorage.setItem('pwd', data)
  },
  [FORGET_PASSWORD] (state) {
    state.savedPassword = null;
    localStorage.removeItem('pwd');
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}