<template>
  <div id="app" class="app">
    <router-view name="sidebar"></router-view>
    <router-view name="header"></router-view>
    <transition name="router" mode="out-in">
      <router-view class="view router-view"></router-view>
    </transition>
    <router-view name="proctoring"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="css">
html,
body {
  height: 100%;
}
.app {
  min-height: 100%;
}
.router-view {
  transition: all .5s ease;
}
.router-enter, .router-leave {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
</style>