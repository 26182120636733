import { render, staticRenderFns } from "./Proctoring.vue?vue&type=template&id=b771b63a&"
import script from "./Proctoring.vue?vue&type=script&lang=js&"
export * from "./Proctoring.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports