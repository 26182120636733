<template>
  <div>
    <nav class="component-navbar main-navbar navbar navbar-expand-lg">
      <div class="container">
        <button :class="{open: !collapsed}" type="button" class="navbar-toggler collapsed" @click="collapsed=!collapsed" v-clickaway="clickaway">
          <span class="navbar-toggler-icon"><div class="menu-btn-burger"></div></span>
        </button>
        <a @click.prevent="logoClick" class="navbar-brand"><img width="75" :src="project_id === 1 ? '/images/1000-BALA-logo.png' : '/images/qabilet.png'" style="visibility: hidden"></a>

        <div class="navbar-collapse desktop-menu" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item d-none">
              <a class="nav-link" href="tel:88000804848"><i class="phone-icon"></i>8 800 080 4848</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'Navbar',
  data () {
    return {
      logged: false,
      collapsed: true,
      project_id: null
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === '' || localStorage.getItem('token') === null);
    if (localStorage.getItem('quiz')){
      const { project_id } = JSON.parse(localStorage.getItem('quiz'));
      this.project_id = project_id
    }
  },
  methods: {
    logoClick(){
      return false;
    },
    logoutMethod() {
      this.logged = false;
      this.logout()
    },
    clickaway () {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  }
}
</script>
