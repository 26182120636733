import * as API from '@/api'

const AUTH_CHECK_IIN_SUCCESS = 'AUTH_CHECK_IIN_SUCCESS'
const AUTH_CHECK_IIN_FAIL = 'AUTH_CHECK_IIN_FAIL'
const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL'
const AUTH_LOGOUT = 'AUTH_LOGOUT'

const state = {
  token: localStorage.getItem('token') || '',
  user: localStorage.getItem('user') || '',
  data: localStorage.getItem('children') || ''
}

const getters = {}

const mutations = {
  [AUTH_CHECK_IIN_SUCCESS] (state, data) {
    if (data.data) {
      localStorage.setItem('children', JSON.stringify(data.data))
      state.data = data.data;
    }
  },
  [AUTH_CHECK_IIN_FAIL] (state) {
    state.data = ''
    localStorage.setItem('children', '')
  },
  [AUTH_LOGIN_SUCCESS] (state, data) {
    state.token = data.token
    state.user = data.user;
    localStorage.setItem('token', data.token)
    localStorage.setItem('user', JSON.stringify(data.user))
  },
  // [AUTH_LOGIN_FAIL] (state, err) {
  [AUTH_LOGIN_FAIL] (state) {
    state.token = ''
    state.user = ''
    localStorage.setItem('token', '')
    localStorage.setItem('user', '')
  },
  //[AUTH_LOGOUT] (state, err) {
  [AUTH_LOGOUT] (state) {
    state.token = ''
    state.user = ''
    localStorage.setItem('token', '')
    localStorage.setItem('user', '')
  }
}

const actions = {
  login ({commit}, data) {
    return API.Auth.save(data)
      .then((res) => {
        commit(AUTH_LOGIN_SUCCESS, res.body)
      })
      .catch((res) => {
        commit(AUTH_LOGIN_FAIL)
        return Promise.reject(res)
      })
  },
  getIin({commit}, data) {
    return API.GetIIN.save(data)
      .then((res) => {
        commit(AUTH_CHECK_IIN_SUCCESS, res.body)
      })
      .catch((res) => {
        commit(AUTH_CHECK_IIN_FAIL)
        return Promise.reject(res)
      })
  },
  logout ({commit}) {
    return API.Logout.save()
      .then((res) => {
        commit(AUTH_LOGOUT)
      })
      .catch((res) => {
        commit(AUTH_LOGOUT)
        return Promise.reject(res)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}